<template>
  <div class="news" v-loading="loading" element-loading-text="拼命加载中">
    <section class="first flex paddT20">
      <div class="first_left">
        <el-carousel height="336px" indicator-position="none">
          <el-carousel-item v-for="(n, i) in bannerList" :key="i" >
            <a
              :href="n.picUrl"
              :title="n.adName"
              @click="goRouter('/news/newDetail', n.id, 0)"
            >
              <img
                  :src="n.thumbnail"
                  alt=""
                  :title="n.title"
                  class="image err_image"
              />
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="first_right">
                  <li class="box">
          <div class="title">
            <h2>政策法规</h2>
            <i
              class="icon icon el-icon-d-arrow-right"
              @click="goRouter('/enterprise/wiki_list','','政策法规')"
            ></i>
          </div>
          <ul>
            <li v-for="(n, i) in list1" :key="i" class="li line_clamp1">
              <a
                href="javascript:void(0);"
                :title="n.title"
                @click="goRouter('/news/newDetail', n.id, 1)"
              >
                <span v-show="n.author">「{{ n.author }}」</span
                >{{ n.title }}
              </a>
            </li>
          </ul>
        </li>
      </div>
    </section>
    <section class="new_box">
      <ul class="list flex">
        <li class="box">
          <div class="title">
            <h2>走进残障群体</h2>
            <i
              class="icon icon el-icon-d-arrow-right"
              @click="goRouter('/enterprise/wiki_list','','走进残障群体')"
            ></i>
          </div>
          <ul>
            <li v-for="(n, i) in list" :key="i" class="li line_clamp1">
              <a
                href="javascript:void(0);"
                :title="n.title"
                @click="goRouter('/news/newDetail', n.id, 1)"
              >
                <span v-show="n.author">「{{ n.author }}」</span
                >{{ n.title }}
              </a>
            </li>
          </ul>
        </li>
        <li class="box">
          <div class="title">
            <h2>残疾人力管理</h2>
            <i
              class="icon el-icon-d-arrow-right"
              @click="goRouter('/enterprise/wiki_list','','人力资源管理')"
            ></i>
          </div>
          <ul>
            <li v-for="(n, i) in list2" :key="i" class="li line_clamp1">
              <a
                href="javascript:void(0);"
                :title="n.title"
                @click="goRouter('/news/newDetail', n.id, 2)"
              >
                <span v-show="n.author">「{{ n.author }}」</span
                >{{ n.title }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </section>
    <section class="in_style">
      <div class="title">
        <h1>残疾人风采</h1>
        <router-link to="people_list" target="_blank" class="more"
          >看更多 <i class="el-icon-d-arrow-right"></i
        ></router-link>
      </div>
      <ul class="list">
        <li class="li" v-for="(n, i) in list3" :key="i">
          <a
            href="javascript:void(0);"
            :title="n.title"
            @click="goRouter('/news/newDetail', n.id, 3)"
          >
            <img :src="n.thumbnail" alt="" class="banner err_avatar" />
            <h2 class="line_clamp2">{{ n.title }}</h2>
            <div class="text line_clamp4">
              {{ n.conAbstract }}
            </div>
          </a>
        </li>
      </ul>
    </section>
  </div>
</template>

<script>
export default {
  name: "index",
  metaInfo: {
    title: "新闻资讯-兰州市残疾人就业创业网络服务平台",
    meta: [
      {
        name: "keywords",
        content: "残疾人就业,残疾人证,中国残疾人联合会,中国残联,",
      },
      {
        name: "description",
        content:
          "残疾人资讯平台为广大残疾人、残疾人工作者和更为广泛的关心、关注中国残疾人事业的群体提供全面、及时、准确的服务。",
      },
    ],

  },
  data() {
    return {
      bannerList: [],
      list: [],
      list1: [],
      list2: [],
      list3: [],
      adList: [],
      page: {
        pageNum: 1,
        pageSize: 10,
        consultationClassName: "",
        leve:'',
        city:'',
      },
      loading: false,
    };
  },
  methods: {
    info() {
      this.loading = true;
      let locationText = localStorage.getItem('locationSelect');
      this.locationSite = localStorage.getItem('locationSite');
      if (this.locationSite === '河南省') {
        this.page.leve = '本省';
        this.page.city = locationText;
      }
      this.swiper();
      this.news();
      this.policy();
      this.message();
      this.styles();
      this.getAdApi();
    },
    //轮播
    swiper() {
      this.$api
        .newsListApi({
          pageNum: 1,
          pageSize: 10,
          consultationClassName: "资讯首页轮播",
        })
        .then((res) => {
          this.loading = false;
          if (res.data.success) {
            this.bannerList = res.data.data.records;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    //走进残障群体
    news() {
        this.page.pageSize = 6;
      this.page.consultationClassName = "走进残障群体";
      this.$api
        .newsListApi(this.page)
        .then((res) => {
          this.loading = false;
          if (res.data.success) {
            this.list = res.data.data.records;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //政策
    policy() {
      this.$api
        .newsListApi({
          pageNum: 1,
          pageSize: 9,
          leve:this.page.leve,
          city:this.page.city,
          consultationClassName: "政策法规",
        })
        .then((res) => {
          this.loading = false;
          if (res.data.success) {
            this.list1 = res.data.data.records;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //人力资源管理
    message() {
      this.$api
        .newsListApi({
          pageNum: 1,
          pageSize: 6,
          leve:this.page.leve,
          city:this.page.city,
          consultationClassName: "人力资源管理",
        })
        .then((res) => {
          this.loading = false;
          if (res.data.success) {
            this.list2 = res.data.data.records;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //残疾人风采
    styles() {
      this.$api
        .newsListApi({
          pageNum: 1,
          pageSize: 6,
          leve:this.page.leve,
          city:this.page.city,
          consultationClassName: "人物故事",
        })
        .then((res) => {
          this.loading = false;
          if (res.data.success) {
            this.list3 = res.data.data.records;
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //广告 40288113735703670173570367970000
    getAdApi() {
      this.$api
        .adApi({ classId: "40288113735703670173570367970000" })
        .then((res) => {
          this.adList = res.data.data;
        });
    },
    goRouter(url, id, i) {
      const { href } = this.$router.resolve({
        path: url,
        query: {
          id: id,
          active: i,
        },
      });
      window.open(href, "_blank");
      this.$api.updataReadApi({ id: id }).then((res) => {});
      console.log(url)
    },
  },
  created() {
    this.info();
    //判断是否移动端
    this.$mobile('https://lzmz.cdpee.org.cn/#/pages/information/index');

  },
  mounted() {},
};
</script>

<style scoped lang="less">
@import "~assets/css/public";

.news {
  margin-top: 10px;
  background: white;

  section {
    width: 1200px;
    margin: 0 auto;
  }

  .first {
    height: auto;
    margin-bottom: 20px;
    .first_left {
      width: 50%;
      position: relative;
      overflow: hidden;
      /deep/.el-carousel{
        .el-carousel__item{
          a{
            display: block;
            .image{
             width: 588px;
             height: 336px;
            }
          }
        }
      }
    }
    .first_right{
        margin-left: 25px;
        width: 50%;
              .box {
        flex: 1;
        margin-right: 20px;
        .title {
          position: relative;
          height: 50px;
          line-height: 50px;
          padding: 0 10px;
          background: #f2f2f2;

          h2 {
            position: relative;
            padding-left: 10px;
            font-size: 22px;
            font-weight: 400;
          }

          h2::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 2px;
            height: 22px;
            background: @00;
          }

          .icon {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            color: @00;
            cursor: pointer;
            font-weight: bold;
          }
        }

        .first {
          margin: 10px 0;
          padding: 0 10px;
          height: auto;

          .info {
            flex: 1;
            margin-right: 10px;
            margin-bottom: 5px;

            img {
              width: 100%;
              height: 145px;
              min-width: 100%;
              min-height: 145px;
              margin: 0 auto;
            }

            img:last-child {
              margin-right: 0;
            }
          }
        }

        .li {
          position: relative;
          padding: 0 20px;
          margin-top: 10px;
          cursor: pointer;
        }

        .li::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
          width: 4px;
          height: 4px;
          background: #333333;
        }
      }
    }
  }


  //首页新闻列表
  .new_box {
    .list {
      .box {
        flex: 1;
        margin-right: 20px;
        .title {
          position: relative;
          height: 50px;
          line-height: 50px;
          padding: 0 10px;
          background: #f2f2f2;

          h2 {
            position: relative;
            padding-left: 10px;
            font-size: 22px;
            font-weight: 400;
          }

          h2::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 2px;
            height: 22px;
            background: @00;
          }

          .icon {
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            color: @00;
            cursor: pointer;
            font-weight: bold;
          }
        }

        .first {
          margin: 10px 0;
          padding: 0 10px;
          height: auto;

          .info {
            flex: 1;
            margin-right: 10px;
            margin-bottom: 5px;

            img {
              width: 100%;
              height: 145px;
              min-width: 100%;
              min-height: 145px;
              margin: 0 auto;
            }

            img:last-child {
              margin-right: 0;
            }
          }
        }

        .li {
          position: relative;
          padding: 0 20px;
          margin-top: 10px;
          cursor: pointer;
        }

        .li::after {
          content: '';
          position: absolute;
          top: 50%;
          left: 10px;
          transform: translateY(-50%);
          width: 4px;
          height: 4px;
          background: #333333;
        }
      }

      .box:last-child {
        margin-right: 0;
      }
    }

  }

  //  残疾人风采
  .in_style {
    padding: 20px 0;

    .title {
      position: relative;
      margin: 20px auto;

      h1 {
        font-size: 24px;
      }

      .more {
        position: absolute;
        top: 10px;
        right: 0;
        color: @00;
      }
    }

    .list {
      padding: 20px 0;
      display: flex;

      .li {
        width: 190px;
        height: 264px;
        border: 1px solid @e1;
        margin-right: 12px;
        cursor: pointer;

        .banner {
          display: block;
          width: 80px;
          height: 80px;
          border-radius: 50%;
          margin: 20px auto;
        }

        h2 {
          font-size: 16px;
          font-weight: 400;
          text-align: center;
        }

        .text {
          padding: 0 15px;
          margin: 10px 0;
          color: @gray;
        }
      }

      .li:last-child {
        margin-right: 0;
      }
    }
  }
}

</style>
